import * as React from 'react';
import cn from 'classnames';

import { useUIConfig } from '../config';

import './spinner.css';

export interface ISpinnerProps extends React.HTMLProps<HTMLDivElement> {
  /** icon definition for icon to spin in middle of div,  */
  icon?: React.ReactNode;

  /** should the spinner wrapper fill the container, meaning the icon is centred */
  fillContainer?: boolean;

  /** text to render below the spinner */
  label?: string;
}

/** Renders a spinner centred in the div that's being wrapped */
export const SpinnerComponent: React.ForwardRefRenderFunction<HTMLDivElement, ISpinnerProps> = (
  { className, icon, fillContainer, label, ...HTMLProps },
  ref
) => {
  const { spinnerIcon } = useUIConfig({ spinnerIcon: icon });
  return (
    <div
      ref={ref}
      className={cn('seccl-spinner', className)}
      {...HTMLProps}
      data-fill-container={fillContainer}
      data-testid="spinner"
    >
      <div className="seccl-spinner-inner" role="status" aria-label="Loading...">
        {spinnerIcon}
      </div>
      {label && (
        <div className="seccl-spinner-label">
          <span>{label}</span>
        </div>
      )}
    </div>
  );
};

export const Spinner = React.forwardRef(SpinnerComponent);
