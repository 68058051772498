import React from 'react';

import { useDebounce } from '../../hooks/useDebounce';
import type { IBaseInputProps } from '../baseInput';
import { BaseInput } from '../baseInput';
import { Icons } from '../icons';
import type { IBaseAutocompleteProps } from './baseAutocomplete.component';
import { BaseAutocomplete } from './baseAutocomplete.component';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface ISearchInputProps<TData extends Record<string, any>>
  extends Omit<
    IBaseAutocompleteProps<TData, false>,
    'multiple' | 'value' | 'onChange' | 'ref' | 'onSelect' | 'options' | 'endAdornment' | 'displayKey'
  > {
  options: TData[];
  onTermChange: (term?: string) => void;
  onSelect: (value?: TData) => void;
  throttleTime?: number;
  tooltip?: string;
  actionLinkText?: string;
  onAction?: () => void;
  showActionLink?: boolean;
  dataTestId?: string;
  placeholder?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function SearchInputComponent<TData extends Record<string, any>>(
  {
    onSelect,
    options,
    onTermChange,
    label,
    throttleTime = 1000,
    loading,
    pending,
    disabled,
    error,
    helperText,
    required,
    placeholder,
    loadingText,
    tooltip,
    dataTestId,
    ...rest
  }: ISearchInputProps<TData>,
  ref?: React.ForwardedRef<HTMLInputElement>
) {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [isTyping, setIsTyping] = React.useState(false);

  const onStopTyping = (term?: string) => {
    setIsTyping(false);
    onTermChange(term);
  };

  const debouncedOnChange = useDebounce(onStopTyping, throttleTime);

  const handleOnChange: IBaseInputProps['onChange'] = event => {
    setIsTyping(true);
    setSearchTerm(event.target.value);
    debouncedOnChange(event.target.value);
  };

  return (
    <BaseAutocomplete
      {...rest}
      options={isTyping || loading || searchTerm === '' ? [] : options}
      blurOnSelect={true}
      clearOnBlur={true}
      onChange={(_, data) => {
        setSearchTerm('');
        onSelect?.(data);
      }}
      loading={loading}
      filterOptions={x => x}
      ref={ref}
      loadingText={searchTerm ? loadingText : undefined}
      renderInput={props => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { value, onChange, className, id, ...inputProps } = props.inputProps;

        return (
          <BaseInput
            placeholder={placeholder}
            required={required}
            pending={pending}
            disabled={disabled}
            error={error}
            label={label}
            helperText={helperText}
            ref={props.InputProps.ref}
            inputProps={{ ...inputProps, className: 'seccl-input' }}
            value={searchTerm}
            onChange={handleOnChange}
            endAdornment={<Icons.Search size={20} />}
            tooltip={tooltip}
            data-testid={dataTestId}
          />
        );
      }}
    />
  );
}

/**
 * A base autocomplete input component extending Material UI.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SearchInput = React.forwardRef(SearchInputComponent) as <TData extends Record<string, any>>(
  props: ISearchInputProps<TData> & React.RefAttributes<HTMLInputElement>
) => ReturnType<typeof SearchInputComponent>;
