import * as React from 'react';
import cn from 'classnames';

import { useUIConfig } from '../config';

import './logo.css';

/**
 * Props for the logo component.
 */

export interface ILogoProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * An override URL for the logo image. If provided, this URL will be used as the background image for the logo; otherwise, the default image URL using the --seccl-logo-bg-url CSS class will be used.
   */
  overrideURL?: string;
  /**
   * A string that describes the logo for accessibility purposes.
   */
  ariaLabel: string;
}

/**
 * A customizable logo component that renders a logo with optional override URL and accessibility support.
 *
 * @param {ILogoProps} props - The props for the IconButton component.
 * @param {React.Ref<HTMLDivElement>} ref Forwarded ref.
 * @returns {React.ReactElement} The rendered logo component.
 */

const LogoComponent: React.ForwardRefRenderFunction<HTMLImageElement, ILogoProps> = (
  { className, ariaLabel, overrideURL, ...rest },
  ref
) => {
  const { logoPath } = useUIConfig();

  return (
    <div className="seccl-logo-container">
      <img
        {...rest}
        className={cn(className, 'seccl-logo')}
        aria-label={ariaLabel}
        src={overrideURL ?? logoPath}
        ref={ref}
      />
    </div>
  );
};

export const Logo = React.forwardRef(LogoComponent);
