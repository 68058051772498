import * as React from 'react';
import { FormHelperText } from '@mui/material';

import { Icons } from '../icons';

import './helperText.css';

/**
 * Type representing helper text(s) for the input.
 * It can be a single React Node or an array of React Nodes.
 */
export type HelperText = React.ReactNode | React.ReactNode[];

/** Props for the HelperText component. */
export interface IHelperTextProps {
  /** Unique identifier for the component. */
  id?: string;

  /** Represents whether the associated input has an error. */
  error?: boolean;

  /** Represents whether the associated input is disabled. */
  disabled?: boolean;

  /** Single or array of helper texts to be displayed beneath the input. */
  text?: HelperText;
}

/**
 * Component to render helper text(s) associated with an input.
 *
 * @param {IHelperTextProps} props - Component props.
 * @returns The rendered element.
 */
export const HelperText: React.FC<IHelperTextProps> = ({ text, disabled, error, id }) => {
  const texts = text ? (Array.isArray(text) ? text : [text]) : [];

  // Filters helper text messages only containing white space, as Zod will render a default message for fields with
  // a message defined as an empty string.
  const filteredTexts = texts.filter(t => (typeof t === 'string' ? t.trim().length > 0 : true));

  if (!filteredTexts.length) {
    return null;
  }

  return (
    <>
      {filteredTexts.map((ht, i) => (
        <FormHelperText
          id={id}
          key={i}
          className="seccl-input-form-helper-text"
          error={error}
          disabled={disabled}
          data-testid={id}
        >
          {error && ht && <Icons.Alert className="seccl-input-form-helper-text-icon" size={15} />}
          {ht}
        </FormHelperText>
      ))}
    </>
  );
};
