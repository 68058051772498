import * as React from 'react';
import type { ButtonProps } from '@mui/material';
import { Button as MuiButton } from '@mui/material';
import cn from 'classnames';

import type { SecclTheme } from '../../utils/theme';
import { Spinner } from '../spinner';

import './button.css';

type RemovedMuiProps =
  | 'variant'
  | 'inputRef'
  | 'ref'
  | 'sx'
  | 'about'
  | 'access'
  | 'action'
  | 'accessKey'
  | 'size'
  | 'color'
  | 'disableFocusRipple'
  | 'disableElevation';

/**
 * Props for the Button component.
 */
export interface IButtonProps extends Omit<ButtonProps, RemovedMuiProps> {
  /** Icon definition for icon before children, uses React.ReactNode. */
  startIcon?: React.ReactNode;

  /** Icon definition for icon after children, uses React.ReactNode. */
  endIcon?: React.ReactNode;

  /** Boolean value to switch between contained and outlined styles. */
  mode?: 'primary' | 'secondary';

  /** Boolean value to determine if the input is pending, showing a spinner. */
  pending?: boolean;

  /** Optional color identifier. */
  theme?: SecclTheme;
  disabled?: boolean;
}

/**
 * Function to generate CSS style object with custom CSS variable values for button colors.
 * @param {SecclTheme} theme - The color identifier.
 * @param {React.CSSProperties} [style={}] - Existing style object to extend.
 */
const setStyle = (theme: SecclTheme, style: React.CSSProperties = {}) => ({
  '--seccl-button-color': `var(--seccl-color-${theme})`,
  '--seccl-button-light': `var(--seccl-color-${theme}-light)`,
  '--seccl-button-dark': `var(--seccl-color-${theme}-dark)`,
  '--seccl-button-border': `1px solid var(--seccl-color-${theme}-light)`,
  '--seccl-button-outlined-border': `1px solid var(--seccl-color-${theme})`,
  '--seccl-button-contrast': `var(--seccl-color-${theme}-contrast)`,
  ...style,
});

/**
 * A customizable button component extending Material UI.
 *
 * @param {IButtonProps} props - The props for the Button component.
 * @param {React.Ref<HTMLButtonElement>} ref Forwarded ref.
 * @returns {React.ReactElement} The rendered Button component.
 */
const ButtonComponent: React.ForwardRefRenderFunction<HTMLButtonElement, IButtonProps> = (
  { className, mode = 'primary', theme = 'accent', children, startIcon, pending, disabled, ...HTMLProps },
  ref
) => {
  return (
    <MuiButton
      {...HTMLProps}
      role={HTMLProps.role || HTMLProps.href ? 'link' : 'button'}
      ref={ref}
      className={cn(className, 'seccl-button')}
      variant={mode === 'secondary' ? 'outlined' : 'contained'}
      disableRipple
      disabled={disabled || !!pending}
      startIcon={pending ? <Spinner className="seccl-button-spinner" /> : startIcon}
      style={setStyle(theme, HTMLProps.style)}
    >
      <span className="seccl-button-content">{children}</span>
    </MuiButton>
  );
};

export const Button = React.forwardRef(ButtonComponent);
