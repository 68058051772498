import React from 'react';

/**
 * `useDebounce` is a custom hook that returns a debounced version of the input function.
 *
 * @template T - A function type that receives an arbitrary number of arguments and may return a result.
 * @param {T} func - The function to be debounced.
 * @param {number} delay - The debounce delay in milliseconds.
 * @returns {T} - A debounced version of the input function.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useDebounce = <T extends (...args: any[]) => any>(func: T, delay: number): T => {
  const funcRef = React.useRef(func);
  funcRef.current = func;

  const timeoutIdRef = React.useRef<NodeJS.Timeout | null>(null);

  const debouncedFunc = React.useCallback(
    (...args: Parameters<T>) => {
      if (timeoutIdRef.current !== null) {
        clearTimeout(timeoutIdRef.current);
      }

      timeoutIdRef.current = setTimeout(() => {
        funcRef.current(...args);
      }, delay);
    },
    [delay]
  );

  React.useEffect(() => {
    return () => {
      if (timeoutIdRef.current !== null) {
        clearTimeout(timeoutIdRef.current);
      }
    };
  }, []);

  return debouncedFunc as T;
};
