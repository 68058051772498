import * as React from 'react';
import { Button } from '@mui/material';

import type { INavigationItem } from './types';

/**
 * Props for MobileNavItem component
 * @extends {INavigationItem}
 */
interface IMobileNavItemProps extends INavigationItem {
  /** The component to render for links, driven off if the href on the navigation item is present */
  linkComponent?: React.ElementType;
}

/**
 * Navigation item component for the desktop header
 * @param {IDesktopHeaderNavItemProps} props - Props for the component
 * @returns The navigation item react component
 */
export const MobileNavItem: React.FC<IMobileNavItemProps> = ({ content, href, linkComponent, menuItems, onClick }) => {
  return (
    <>
      <Button
        LinkComponent={linkComponent}
        href={href}
        disableFocusRipple
        disableRipple
        disableTouchRipple
        onClick={onClick}
        color="inherit"
        className="seccl-navigation-button mobile"
      >
        {content}
      </Button>
      {menuItems?.map((item, i) => (
        <Button
          key={i}
          LinkComponent={linkComponent}
          href={item.href}
          disableFocusRipple
          disableRipple
          disableTouchRipple
          onClick={item.onClick}
          color="inherit"
          className="seccl-navigation-button mobile sub-navigation"
        >
          {item.content}
        </Button>
      ))}
    </>
  );
};
