import React, { useState } from 'react';

import type { IPopoverOriginProps } from './popover.component';

interface IPopoverOptions extends IPopoverOriginProps {
  openOnHover?: boolean;
}
/**
 * A custom React hook that provides popover functionality.
 *
 * @param options - Additional options for the popover origin.
 * @returns An object containing buttonProps and popoverProps.
 */
export const usePopover = ({ openOnHover, ...options }: IPopoverOptions = {}) => {
  const [activePopoverId, setActivePopoverId] = React.useState<string>();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  /**
   * Event handler for the button click.
   *
   * @param event - The click event.
   */
  const onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setActivePopoverId(event.currentTarget.id);
    setAnchorEl(event.currentTarget);
  };

  const triggerOpen = (event: React.KeyboardEvent<HTMLElement>) => {
    event.stopPropagation();
    setActivePopoverId(event.currentTarget.id);
    setAnchorEl(event.currentTarget);
  };

  const onMouseOver = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setActivePopoverId(event.currentTarget.id);
    setAnchorEl(event.currentTarget);
  };

  /**
   * Closes the popover by setting the anchor element to null.
   */
  const onClose = () => {
    setActivePopoverId(undefined);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  /**
   * Close the popup on window resize
   */
  React.useEffect(() => {
    const onResize = () => {
      if (!anchorEl) setAnchorEl(null);
    };

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Props for the button triggering the popover.
   */
  const buttonProps = {
    'aria-controls': open ? activePopoverId : undefined,
    'aria-haspopup': true,
    'aria-expanded': open ? true : undefined,
    // https://dequeuniversity.com/rules/axe/4.4/aria-hidden-focus
    tabIndex: open ? -1 : 0,
    onClick,
    onMouseOver: openOnHover ? onMouseOver : undefined,
  };

  /**
   * Props for the popover component.
   */
  const popoverProps = {
    open,
    onClose,
    anchorEl,
    ...options,
  };

  return { buttonProps, popoverProps, activePopoverId, triggerOpen };
};
