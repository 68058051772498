import * as React from 'react';
import type { IconButtonProps } from '@mui/material';
import { IconButton as MuiIconButton } from '@mui/material';
import cn from 'classnames';

import './iconButton.css';

type RemovedMuiProps =
  | 'sx'
  | 'size'
  | 'color'
  | 'disableFocusRipple'
  | 'disableRipple'
  | 'disableTouchRipple'
  | 'disableElevation'
  | 'edge';

/**
 * Props for the Icon Button component.
 */
export interface IIconButtonProps extends Omit<IconButtonProps, RemovedMuiProps> {
  ariaLabel: string;
}

/**
 * A customizable icon button component extending Material UI.
 *
 * @param {IIconButtonProps} props - The props for the IconButton component.
 * @param {React.Ref<HTMLButtonElement>} ref Forwarded ref.
 * @returns {React.ReactElement} The rendered IconButton component.
 */
const IconButtonComponent: React.ForwardRefRenderFunction<HTMLButtonElement, IIconButtonProps> = (
  { className, children, ariaLabel, ...rest },
  ref
) => {
  return (
    <MuiIconButton
      color="inherit"
      disableFocusRipple
      disableRipple
      disableTouchRipple
      {...rest}
      className={cn('seccl-icon-button', className)}
      aria-label={ariaLabel}
      ref={ref}
    >
      {children}
    </MuiIconButton>
  );
};

export const IconButton = React.forwardRef(IconButtonComponent);
