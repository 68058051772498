import * as React from 'react';
import type { AutocompleteProps, AutocompleteValue } from '@mui/material';
import { Autocomplete, FormLabel, InputBase, Popper } from '@mui/material';
import cn from 'classnames';

import { getAdornment } from '../baseInput/adornments.component';
import { HelperText } from '../helperText';
import { IconButton } from '../iconButton';
import { Icons } from '../icons';
import { Pill } from '../pill';
import type { ISelectOption } from '../selectInput';
import { Tooltip } from '../tooltip';

import './baseAutocomplete.css';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IBaseAutocompleteProps<TData extends Record<string, any>, Multiple extends boolean | undefined>
  extends Omit<AutocompleteProps<TData, Multiple, true, false>, 'renderInput'> {
  /**
   * Determines if the input is in a pending state.
   * If true, a spinner is shown to indicate loading or processing status.
   */
  pending?: boolean;

  /**
   * Label text for the input.
   */
  label?: React.ReactNode;

  /**
   * Helper text or array of helper texts that are displayed below the input.
   * Can be used to provide additional guidance or explanation about the input.
   */
  helperText?: HelperText;

  /**
   * If true, the input field is marked as required, meaning the user must provide a value before submitting a form.
   */
  required?: boolean;

  /**
   * If true, the input field is in an error state. This is typically used to indicate that the input value is invalid.
   */
  error?: boolean;

  /**
   * A React node to be placed at the end of the input.
   * This is typically used for providing additional information or controls at the end of an input.
   */
  endAdornment?: React.ReactNode;
  /**
   * Render the input.
   */
  renderInput?: AutocompleteProps<ISelectOption, Multiple, true, false>['renderInput'];

  /**
   * The tooltip text to be displayed when hovering over the info icon. Info icon will only render if tooltip is not undefined.
   */
  tooltip?: string;

  /**
   * Whether the value is clearable. This will render a clear icon on hover when a value is selected.
   */
  clearable?: boolean;
  /**
   * The function to call when the clear icon is clicked.
   */
  onClear?: () => void;

  readOnlyTags?: string[];

  /**
   * Placeholder text
   */
  placeholder?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function BaseAutocompleteComponent<TData extends Record<string, any>, Multiple extends boolean | undefined>(
  {
    options,
    required,
    pending,
    disabled,
    label,
    id,
    className,
    error,
    helperText,
    placeholder,
    endAdornment,
    onBlur,
    value,
    tooltip,
    clearable,
    readOnlyTags,
    onClear,
    ...rest
  }: IBaseAutocompleteProps<TData, Multiple>,
  ref?: React.ForwardedRef<HTMLInputElement>
) {
  const reactId = React.useId();
  const inputId = id ?? reactId;

  const helperTextId = `${inputId}-helper-text`;
  const clickHandlerTestId = `${inputId}-click-handler`;
  const popoverTestId = `${inputId}-popover`;

  const renderTags: IBaseAutocompleteProps<TData, Multiple>['renderTags'] = (value, getTagProps) => {
    // Add the original index to each value so we can sort them later whilst maintaining the internal index
    const originalValues = value.map((v, i) => ({ ...v, originalIndex: i }));

    // Sort the values so that readonly tags are always first
    const orderedValues = originalValues.sort(a => (readOnlyTags?.includes(a.id) ? -1 : 1));

    // Render the tags
    return orderedValues.map(option => {
      const { onDelete, disabled, className, key, ...rest } = getTagProps({ index: option.originalIndex });
      const readonly = readOnlyTags?.includes(option.id);
      return (
        <Pill
          {...rest}
          color="info"
          content={option.name}
          data-disabled={disabled}
          className={cn(className, 'seccl-auto-complete-pill')}
          key={key ?? option.originalIndex}
          endAdornment={
            <>
              {!readonly && (
                <IconButton
                  className="seccl-auto-complete-pill-close"
                  ariaLabel={`Remove ${option.name}`}
                  onClick={onDelete}
                >
                  <Icons.Close />
                </IconButton>
              )}
            </>
          }
        />
      );
    });
  };

  const renderInput: IBaseAutocompleteProps<TData, Multiple>['renderInput'] = params => {
    const { InputLabelProps, InputProps } = params;
    return (
      <>
        {label && (
          <Tooltip title={tooltip} infoIcon>
            <FormLabel className="seccl-input-label" {...InputLabelProps} required={required} disabled={disabled}>
              {label}
            </FormLabel>
          </Tooltip>
        )}
        <InputBase
          {...InputProps}
          className={cn(className, 'seccl-input-wrapper', 'seccl-auto-complete-input-wrapper')}
          inputProps={{ ...params.inputProps, className: cn('seccl-input', 'seccl-auto-complete-input') }}
          startAdornment={params.InputProps.startAdornment}
          endAdornment={getAdornment({ position: 'end', content: endAdornment, pending, clearable, onClear })}
          placeholder={placeholder}
          error={error}
          disabled={disabled}
          // Our base input wants an input ref, mui autocomplete wants a div ref
          onBlur={onBlur as React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>}
          aria-describedby={helperText ? helperTextId : undefined}
          data-testid={clickHandlerTestId}
        />
        <HelperText error={error} disabled={disabled} id={helperTextId} text={helperText} />
      </>
    );
  };

  const PopperComponent: IBaseAutocompleteProps<TData, Multiple>['PopperComponent'] = popperProps => {
    return (
      <Popper
        {...popperProps}
        placement="bottom-start"
        style={{
          minWidth: 'fit-content',
          width: popperProps.anchorEl ? (popperProps.anchorEl as HTMLElement).clientWidth : undefined,
        }}
        data-testid={popoverTestId}
      />
    );
  };

  return (
    <Autocomplete
      className="seccl-input-form-control"
      classes={{
        endAdornment: 'seccl-auto-complete-end-adornment',
        inputRoot: 'seccl-auto-complete-input-root',
        option: 'seccl-auto-complete-option',
        groupLabel: 'seccl-auto-complete-group-label',
      }}
      options={options}
      getOptionLabel={option => (typeof option === 'string' ? option : option.id)}
      popupIcon={undefined}
      disabled={disabled}
      renderTags={renderTags}
      renderInput={renderInput}
      renderOption={(props, option) => <li {...props}>{option.name}</li>}
      filterOptions={(options, value) =>
        options.filter(option => option.name.toLowerCase().includes(value.inputValue.toLowerCase()))
      }
      slots={{
        popper: PopperComponent,
      }}
      // Workaround for MUI controlled input bug
      // https://github.com/mui/material-ui/issues/18173
      value={value ?? (null as unknown as AutocompleteValue<TData, Multiple, true, false>)}
      {...rest}
      ref={ref}
    />
  );
}

/**
 * A base autocomplete input component extending Material UI.
 */
export const BaseAutocomplete = React.forwardRef(BaseAutocompleteComponent) as <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TData extends Record<string, any>,
  Multiple extends boolean | undefined
>(
  props: IBaseAutocompleteProps<TData, Multiple> & React.RefAttributes<HTMLInputElement>
) => ReturnType<typeof BaseAutocompleteComponent>;
