import * as React from 'react';
import type { InputProps } from '@mui/material';
import { FormLabel, InputBase } from '@mui/material';
import cn from 'classnames';

import { HelperText } from '../helperText';
import { Tooltip } from '../tooltip';
import { getAdornment } from './adornments.component';

import './baseInput.css';

type RemovedMuiProps = 'variant' | 'inputRef' | 'ref' | 'sx' | 'slots' | 'slot' | 'slotProps' | 'helperText';

/**
 * Props for the InputBase component.
 */
export interface IBaseInputProps extends Omit<InputProps, RemovedMuiProps> {
  /**
   * Boolean value to determine if the input is pending, showing a spinner.
   */
  pending?: boolean;

  /**
   * Label for the input.
   */
  label?: React.ReactNode;

  /**
   * Single or array of helper texts to be displayed beneath the input.
   */
  helperText?: HelperText;

  /**
   * Single or array of error texts to be displayed beneath the input.
   */
  errorText?: string;

  /**
   * The tooltip text to be displayed when hovering over the info icon. Info icon will only render if tooltip is not undefined.
   */
  tooltip?: string;
}

/**
 * Base input component extending Material Ui.
 *
 * @param {IBaseInputProps} props Component props.
 * @param {React.Ref<HTMLInputElement>} ref Forwarded ref.
 * @returns {React.ReactElement} The rendered element.
 */
const BaseInputComponent: React.ForwardRefRenderFunction<HTMLInputElement, IBaseInputProps> = (
  {
    id,
    endAdornment,
    startAdornment,
    label,
    className,
    pending,
    tooltip,
    helperText,
    error,
    disabled,
    required,
    inputProps,
    errorText,
    name,
    ...rest
  },
  ref
) => {
  const reactId = React.useId();
  const inputId = id ?? reactId;

  const errorTextId = `${inputId}-error-text`;

  return (
    <div className="seccl-input-form-control">
      {label && (
        <Tooltip title={tooltip} infoIcon>
          <FormLabel className="seccl-input-label" htmlFor={inputId} required={required} disabled={disabled}>
            {label}
          </FormLabel>
        </Tooltip>
      )}
      <InputBase
        id={inputId}
        className={cn(className, 'seccl-input-wrapper')}
        inputProps={{ ...(inputProps ?? {}), className: 'seccl-input', 'data-testid': `${name}-input` }}
        startAdornment={getAdornment({ position: 'start', content: startAdornment })}
        endAdornment={getAdornment({ position: 'end', content: endAdornment, pending: pending })}
        error={error}
        disabled={disabled}
        inputRef={ref}
        aria-describedby={errorText ? errorTextId : undefined}
        {...rest}
      />
      <HelperText disabled={disabled} text={helperText} />
      <HelperText error={error} disabled={disabled} id={errorTextId} text={errorText} />
    </div>
  );
};

export const BaseInput = React.forwardRef(BaseInputComponent);
