import * as React from 'react';
import { ClickAwayListener, ListItemIcon, Menu, MenuItem } from '@mui/material';

import type { IPopoverProps } from './popover.component';

import './popoverMenu.css';

/**
 * Represents an item in the menu.
 */
export interface IMenuItem {
  /**
   * The callback function invoked when the menu item is clicked.
   */
  onClick?: () => void;
  /**
   * The href link, when this is present the linkComponent passed to the parent will be used
   */
  href?: string;
  /**
   * The content of the menu item. It can be a string or a React node.
   */
  content: string | React.ReactNode;
  /**
   * The icon displayed with the menu item.
   */
  icon?: React.ReactNode;
  /**
   * A boolean representing whether a line separator is rendered above the item.
   */
  separator?: boolean;
  /**
   * Whether the menu item is disabled
   */
  disabled?: boolean;
  /**
   *  Whether the menu item is hidden
   */
  hidden?: boolean;
}

/**
 * Props for the PopoverMenu component.
 */
export interface IPopoverMenuProps extends IPopoverProps {
  /**
   * An array of menu items to be displayed in the popover menu.
   */
  menuItems: IMenuItem[];
  /**
   * The link component to render when a href is passed to a menu item
   */
  linkComponent?: React.ElementType;
}

/**
 * A React component that renders a menu inside a popover.
 *
 * @param ref - A ref to the underlying HTMLDivElement.
 * @param {IPopoverMenuProps} props - Other props passed to the component.
 * @returns The rendered PopoverMenu component.
 */
const PopoverMenuComponent: React.ForwardRefRenderFunction<HTMLDivElement, IPopoverMenuProps> = (
  { menuItems, linkComponent, onClickAway, ...rest },
  ref
) => {
  const timeoutId = React.useRef<NodeJS.Timeout>(undefined);
  const getLinkProps = (href?: string) =>
    href && linkComponent
      ? {
          href,
          component: linkComponent,
        }
      : {};

  React.useEffect(() => {
    return () => clearTimeout(timeoutId.current);
  }, []);

  return (
    <ClickAwayListener
      onClickAway={() => {
        onClickAway?.();
        rest.onClose();
      }}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
    >
      <Menu
        ref={ref}
        {...rest}
        classes={{
          root: 'seccl-popover-menu',
          list: 'seccl-popover-menu-list',
        }}
        onMouseOver={() => {
          clearTimeout(timeoutId.current);
        }}
        onMouseLeave={() => {
          timeoutId.current = setTimeout(() => {
            rest.onClose();
          }, 500);
        }}
        aria-modal
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
          ...(rest.anchorOrigin ?? {}),
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
          ...(rest.transformOrigin ?? {}),
        }}
      >
        {menuItems.map((item, i) => {
          if (item.hidden) return null;
          return (
            <MenuItem
              data-separator={item.separator}
              className="seccl-menu-item"
              disableTouchRipple
              key={i}
              disabled={item.disabled}
              onClick={() => {
                item.onClick?.();
                rest.onClose();
              }}
              {...getLinkProps(item.href)}
            >
              {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
              {item.content}
            </MenuItem>
          );
        })}
      </Menu>
    </ClickAwayListener>
  );
};

/**
 * A forwardRef wrapper for the PopoverMenuComponent.
 */
export const PopoverMenu = React.forwardRef(PopoverMenuComponent);
