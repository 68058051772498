import React, { useContext } from 'react';
import { useClearCache } from '@seccl/api-swr';
import { Resource } from '@seccl/client';
import { stringifyQuery } from '@seccl/ui';
import { Header } from '@seccl/ui/components/header';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { FirmContext } from '../../context/firm.context';
import { useNProgress } from '../../hooks';
import { useScopes } from '../../hooks/useScope';
import { routes } from '../../routes';
import { ClientSearchInput } from '../client/clientSearchInput';
import ErrorBoundary from '../errors/errorBoundary/errorBoundary';
import { defaultTransfersColumnState } from '../tables/allTransfersTable.constants';

import styles from './layout.module.scss';

const Footer = dynamic(() => import('./footer').then(mod => mod.Footer));

/**
 * IProps Interface
 */
interface IProps {
  /** The title of the page, which will be displayed in the browser tab. */
  title?: string;
  /**  Should the body have a background fill. */
  fill?: boolean;
}

/**
 * Layout Component
 * A reusable layout component for a Next.js app, including a header, main content area, and footer.
 * @param {React.PropsWithChildren<IProps>} props - Props for the Layout component
 * @returns {React.ReactElement} JSX element for the layout
 */
export const Layout: React.FC<React.PropsWithChildren<IProps>> = ({ children, title, fill }) => {
  const { footerCopyright, windowTitle, authenticated } = useContext(FirmContext);
  const router = useRouter();
  const { hasAccess } = useScopes();
  const clearCache = useClearCache();
  useNProgress();

  const canViewAdvisoryModels = hasAccess(Resource.AdvisoryModel);
  const canViewDiscretionaryModels = hasAccess(Resource.Model);
  const canViewUsers = hasAccess(Resource.User);
  const canViewInstruments = hasAccess(Resource.Instrument);
  const canViewTransactions = hasAccess(Resource.Transaction);

  return (
    <ErrorBoundary>
      <Head>
        <title>{title ? `${title} | ${windowTitle}` : windowTitle}</title>
      </Head>
      <div className={styles.layout}>
        {authenticated ? (
          <Header
            linkComponent={Link}
            navigationItems={[
              {
                content: 'Dashboard',
                href: routes.dashboard,
              },
              {
                content: 'Clients',
                href: routes.clients.root,
              },
              {
                content: 'Tools',
                menuItems: [
                  {
                    content: 'Models',
                    href: canViewAdvisoryModels ? routes.models('advisory').root : routes.models('discretionary').root,
                    hidden: !canViewAdvisoryModels && !canViewDiscretionaryModels,
                  },
                  {
                    content: 'Illustrations',
                    href: routes.illustrations.root,
                  },
                  {
                    content: 'Transactions',
                    href: routes.transactions.root,
                    hidden: !canViewTransactions,
                  },
                ],
              },
              {
                content: 'Instruments',
                href: routes.instruments.root,
                hidden: !canViewInstruments,
              },
              {
                content: 'Reporting',
                href: routes.reporting,
              },
              {
                content: 'Transfers',
                href: routes.transfers(stringifyQuery(defaultTransfersColumnState)),
              },
            ]}
            searchComponent={
              <ClientSearchInput
                onSelectClient={clientId => {
                  if (clientId) {
                    router.push(routes.clients.client(clientId).accounts);
                  }
                }}
              />
            }
            settingsMenu={{
              menuItems: [
                {
                  content: 'Firm users',
                  href: routes.users.root,
                  hidden: !canViewUsers,
                },
                {
                  separator: true,
                  content: 'Log out',
                  onClick: clearCache,
                  href: routes.auth.auth0.logout,
                },
              ],
            }}
          />
        ) : (
          <div />
        )}
        <main className={styles.main} data-fill={fill}>
          {children}
        </main>
        {authenticated && <Footer footerCopyright={footerCopyright} />}
      </div>
    </ErrorBoundary>
  );
};
