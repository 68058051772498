import * as React from 'react';
import type { TooltipProps } from '@mui/material';
import { Tooltip as MuiTooltip } from '@mui/material';
import cn from 'classnames';

import { Icons } from '../icons';

import './tooltip.css';

/**
 * Props for the Tooltip component.
 */

type RemovedMuiProps = 'ref' | 'itemref' | 'arrow' | 'title';

export interface ITooltipProps extends Omit<TooltipProps, RemovedMuiProps> {
  /**
   * The ID of the tooltip.
   */
  id?: string;
  /**
   * Determines whether to show an information icon after the tooltip content.
   */
  infoIcon?: boolean;
  /**
   * Title to be displayed on hover of the tooltip.
   */
  title?: string;
}
/**
 * A custom tooltip component that extends the MuiTooltip component from Material-UI.
 *
 * @component
 * @param {ITooltipProps} props - The props for the Tooltip component.
 * @param {React.Ref<HTMLDivElement>} ref - Forwarded ref.
 * @returns {React.ReactElement} The re pndered Tooltip component.
 */
const TooltipComponent: React.ForwardRefRenderFunction<HTMLDivElement, React.PropsWithChildren<ITooltipProps>> = (
  { className, children, infoIcon, title, ...rest },
  ref
) => {
  if (!title) {
    return children;
  }

  return (
    <div className={cn(className, 'seccl-tooltip-wrapper')} data-testid={'tooltip'}>
      {infoIcon && children}
      <MuiTooltip
        {...rest}
        title={title}
        ref={ref}
        className={'seccl-tooltip'}
        classes={{
          tooltip: 'seccl-tooltip-popper',
        }}
        describeChild
        PopperProps={{
          sx: { zIndex: 1000 },
        }}
      >
        <div>
          {infoIcon && <Icons.Info className={'seccl-tooltip-icon'} />}
          {!infoIcon && children}
        </div>
      </MuiTooltip>
    </div>
  );
};

/**
 * A tooltip component that can be used to provide additional information or context to elements.
 */
export const Tooltip = React.forwardRef(TooltipComponent);
