import * as React from 'react';

import { IconButton } from '../iconButton';
import type { usePopover } from '../popover';
import { PopoverMenu } from '../popover';
import type { IIconNavigationItem } from './types';

/**
 * Props for IconNavItem component
 * @extends {IIconNavigationItem}
 */
interface IDesktopHeaderIconNavItemProps extends IIconNavigationItem {
  /** The component to render for links, driven off if the href on the navigation item is present */
  linkComponent?: React.ElementType;
  /** Props returned from the usePopover hook */
  popoverProps: ReturnType<typeof usePopover>;
  /** Aria label for the icon button */
  ariaLabel: string;
}

/**
 * Navigation item component with an icon for the desktop header
 * @param {IDesktopHeaderIconNavItemProps} props - Props for the component
 * @returns The navigation icon item react component
 */
export const IconNavItem: React.FC<IDesktopHeaderIconNavItemProps> = ({
  icon,
  menuItems,
  linkComponent,
  ariaLabel,
  popoverProps: { activePopoverId, buttonProps, popoverProps },
}) => {
  const id = React.useId();
  return (
    <>
      <IconButton
        className="seccl-navigation-icon-button"
        LinkComponent={linkComponent}
        ariaLabel={ariaLabel}
        id={id}
        {...buttonProps}
      >
        {icon}
      </IconButton>
      {!!menuItems?.length && (
        <PopoverMenu
          {...popoverProps}
          open={activePopoverId === id && popoverProps.open}
          menuItems={menuItems}
          linkComponent={linkComponent}
        />
      )}
    </>
  );
};
