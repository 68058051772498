import React from 'react';
import type { CustomContentProps, SnackbarKey } from 'notistack';
import { useSnackbar } from 'notistack';

/**
 * Custom hook for displaying toast notifications using the 'notistack' library.
 *
 * @returns {Function} A dispatch function that can be used to show a toast notification.
 */

interface IDispatchToastConfig {
  message?: CustomContentProps['message'];
  title: string;
  variant: CustomContentProps['variant'];
  anchorOrigin?: CustomContentProps['anchorOrigin'];
  persist?: boolean;
  action?: CustomContentProps['action'];
  autoHideDuration?: number;
  key?: string;
}

export const useToast = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const errorSnackbarKeys = React.useRef<Set<SnackbarKey>>(new Set());

  const onCloseSnackbar = React.useCallback(
    (toastId?: SnackbarKey) => {
      if (toastId && errorSnackbarKeys.current) {
        errorSnackbarKeys.current?.delete(toastId);
        closeSnackbar(toastId);
        return;
      }

      errorSnackbarKeys.current = new Set();
      closeSnackbar();
    },
    [closeSnackbar]
  );

  React.useEffect(() => {
    return () => {
      errorSnackbarKeys.current?.forEach(id => closeSnackbar(id));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Dispatches a toast notification with the specified configuration.
   *
   * @param {IDispatchToastConfig} config - The configuration for the toast notification.
   */
  const dispatchToast = ({
    message = '',
    title,
    variant,
    anchorOrigin,
    persist = false,
    action,
    autoHideDuration,
    key,
  }: IDispatchToastConfig) => {
    const id = enqueueSnackbar(message, {
      variant,
      title,
      anchorOrigin,
      persist,
      action,
      autoHideDuration,
      key,
    });
    if (variant === 'error') {
      errorSnackbarKeys.current.add(id);
    }
    return id;
  };

  // Note - The type of the snackbar key returned from dispatchToast can be string | number.
  // When passing this to closeSnackbar, it must be the same type.
  // You can also use the key property in the config object to specify a key.
  return { dispatchToast, closeToast: onCloseSnackbar };
};
