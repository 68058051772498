import React from 'react';
import { useRouter } from 'next/router';
import type { NProgress as NProgressType } from 'nprogress';
const loadNProgress = () => import('nprogress').then(module => module.default);

let NProgress: NProgressType;

export const useNProgress = () => {
  const router = useRouter();
  const timeout = React.useRef<NodeJS.Timeout>(undefined);
  const isRouteChanging = React.useRef<boolean>(false);

  React.useEffect(() => {
    let isMounted = true;

    const handleRouteChange = async (_url: string, { shallow }: { shallow: boolean }) => {
      if (!shallow) {
        isRouteChanging.current = true;

        timeout.current = setTimeout(async () => {
          if (isRouteChanging.current && isMounted) {
            NProgress = NProgress || (await loadNProgress());
            NProgress.configure({ showSpinner: false, minimum: 0.2 });
            NProgress.start();
          }
        }, 500);
      }
    };

    const handleComplete = () => {
      isRouteChanging.current = false;
      if (NProgress) NProgress.done();
      clearTimeout(timeout.current);
    };

    router.events.on('routeChangeStart', handleRouteChange);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);

    return () => {
      isMounted = false;
      router.events.off('routeChangeStart', handleRouteChange);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleComplete);
      clearTimeout(timeout.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
