import * as React from 'react';
import set from 'lodash/set';

import type { KeyMap } from '../utils/keyMap';
import { mapFromKeyMap, mapKeys } from '../utils/keyMap';
import { flattenObject } from '../utils/objects';

/**
 * Interface for creating navigation using query string
 */
export interface ICreateUseQueryStringNavigation {
  /**
   * Function to navigate to a given query string
   * @param {string} qs - The query string to navigate to.
   */
  navigate: (qs: string) => void;

  /**
   * Current search query string
   */
  search: string;
}

/**
 * Parse a single query pair into an object.
 * @param {string} pair - The query pair string.
 * @param {object} result - The object to store the parsed result.
 */
function parseQueryPair(pair: string, result: Record<string, unknown>): void {
  let [key] = pair.split('=');
  const [, value] = pair.split('=');

  if (value !== undefined) {
    // Parse values
    let parsedValue: string | string[] | number | boolean | object = value;

    if (value === 'true' || value === 'false') {
      parsedValue = value === 'true';
    } else if (!isNaN(Number(value))) {
      parsedValue = Number(value);
    } else if (key.includes('[]')) {
      key = key.replace('[]', '');
      parsedValue = value.split('|');
    }

    // Use lodash's set function to set the value at the key path
    set(result, key, parsedValue);
  }
}

/**
 * Function to parse a search query string into an object.
 * @template T - The type of the parsed search object.
 * @param {string} search - The search query string.
 * @returns {T} - The parsed search object.
 */
export function parseSearch<T>(search: string): T {
  const result = {};
  try {
    const formattedSearch = decodeURIComponent(search.split('?')[1] || '');
    const pairs = formattedSearch.split('&');

    for (const pair of pairs) {
      parseQueryPair(pair, result);
    }
  } catch {
    // If an error is thrown, treat as if we didn't receive anything
  }

  return result as T;
}

/**
 * Function to stringify a query object into a search string.
 * @param {any} query - The query object.
 * @param {string} [prefix] - The prefix for nested keys.
 * @returns {string} - The stringified query.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function stringifyQuery(query: any, prefix?: string): string {
  const pairs = [];
  const sortedKeys = Object.keys(query).sort();

  for (const key of sortedKeys) {
    if (query[key] !== undefined && query[key] !== null && query[key] !== '') {
      let value = query[key];

      if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
        // Recursive call for nested objects, add key to prefix with '.'
        pairs.push(stringifyQuery(value, prefix ? `${prefix}.${key}` : key));
      } else {
        // Apply prefix if there is one
        let prefixedKey = prefix ? `${prefix}.${key}` : key;

        if (typeof value === 'boolean' || typeof value === 'number') {
          value = value.toString();
        } else if (Array.isArray(value)) {
          prefixedKey = `${prefixedKey}[]`;
          value = value.join('|');
        }

        pairs.push(`${prefixedKey}=${value}`);
      }
    }
  }

  return pairs.join('&');
}

/**
 * Custom hook to use a query string for navigation.
 * @template State - The state object type.
 * @param {ICreateUseQueryStringNavigation} navigation - Object for creating navigation.
 * @param {KeyMap<State>} keyMap - Key map for the state.
 * @returns {{ search: State; setQueryString: (changes: Partial<State>) => void }} - The parsed search and function to set query string.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useQueryString<State extends Record<string, any>>(
  navigation: ICreateUseQueryStringNavigation,
  keyMap?: KeyMap<State>
) {
  const { search, navigate } = navigation;
  // Memoized parsed search
  const searchParsed = React.useMemo(
    () => (keyMap ? mapKeys(parseSearch<State>(search), keyMap) : parseSearch<State>(search)),
    [search, keyMap]
  );

  // Callback to set the query string
  const setQueryString = React.useCallback<(changes: Partial<State>) => void>(
    changes => {
      const mappedChanges = keyMap ? mapFromKeyMap(changes, keyMap) : changes;

      const updated = { ...parseSearch<State>(search), ...mappedChanges };
      const flattened = flattenObject(updated);

      const filteredUpdated = Object.fromEntries<string>(
        Object.entries(flattened).filter(([, value]) => !(Array.isArray(value) && value.length === 0))
      );

      const newQueryString = stringifyQuery(filteredUpdated);

      if (newQueryString !== search) {
        navigate(newQueryString);
      }
    },
    [search, keyMap, navigate]
  );

  return { search: searchParsed, setQueryString };
}
