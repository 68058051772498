/* eslint-disable @typescript-eslint/no-explicit-any */
export type KeyMap<T> = Partial<Record<keyof T, string>>;

/**
 * Map the keys of a source object based on a keyMap object.
 *
 * @template T - The type of the items in the source object.
 * @template K - The type of the items in the keyMap object.
 * @param {T} source - An source object.
 * @param {K} keyMap - A key map object.
 * @returns {T} - The mapped source object.
 */
export const mapKeys = <T extends Record<string, any>, K extends KeyMap<T>>(source: T, keyMap: K): T => {
  const mapped: Partial<T> = {};
  for (const key in keyMap) {
    const mappedKey = keyMap[key];
    if (mappedKey !== undefined && source[mappedKey] !== undefined) {
      mapped[key] = source[mappedKey];
    }
  }
  return mapped as T;
};

/**
 * Prefix all keys in the given keyMap with the specified prefix.
 *
 * @template T - The type of the items in the keyMap object.
 * @param {string} prefix - The prefix to be added.
 * @param {KeyMap<T>} keyMap - The key map object.
 * @returns {KeyMap<T>} - The key map object with prefixed keys.
 */
export const getKeyMapWithPrefix = <T = object>(prefix: string, keyMap: KeyMap<T>): KeyMap<T> => {
  const prefixedKeyMap = {} as KeyMap<T>;

  for (const key in keyMap) {
    const keyTyped = key as keyof KeyMap<T>;
    const value = prefix ? `${prefix}_${keyMap[keyTyped]}` : keyMap[keyTyped];
    prefixedKeyMap[keyTyped] = value;
  }
  return prefixedKeyMap;
};

/**
 * Map the keys of a changes object based on a keyMap object.
 *
 * @template T - The type of the items in the changes object.
 * @param {Partial<T>} changes - An object containing changes.
 * @param {KeyMap<T>} keyMap - A key map object.
 * @returns {Record<string, any>} - The changes object with keys mapped based on the keyMap.
 */
export const mapFromKeyMap = <T>(source: Partial<T>, keyMap: KeyMap<T>): { [key: string]: any } => {
  const mappedChanges: { [key: string]: any } = {};
  for (const key in source) {
    const mappedKey = keyMap[key];
    if (mappedKey !== undefined) {
      mappedChanges[mappedKey] = source[key];
    }
  }
  return mappedChanges;
};
