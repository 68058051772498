import * as React from 'react';
import cn from 'classnames';

import type { SecclTheme } from '../../utils/theme';

import './pill.css';

export interface IPillProps extends Omit<React.HTMLProps<HTMLDivElement>, 'ref'> {
  /** The content text to display within the pill. */
  content: string;
  /** Optional color identifier for the pill. */
  theme?: SecclTheme;
  /** Optional element to display before the content. */
  startAdornment?: React.ReactNode;
  /** Optional element to display after the content. */
  endAdornment?: React.ReactNode;
}

/**
 * Function to generate CSS style object with custom CSS variable values for pill colors.
 * @param {SecclTheme} theme - The color identifier.
 * @param {React.CSSProperties} [style={}] - Existing style object to extend.
 */
const setStyle = (color: SecclTheme, style: React.CSSProperties = {}) => ({
  ...style,
  '--seccl-pill-icon-color': `var(--seccl-color-${color})`,
  '--seccl-pill-bg-color': `var(--seccl-color-${color}-light)`,
  '--seccl-pill-text-color': `var(--seccl-color-${color}-darker)`,
});

const PillComponent: React.ForwardRefRenderFunction<HTMLDivElement, IPillProps> = (
  { content, theme = 'brand-primary', className, startAdornment, endAdornment, ...HTMLProps },
  ref
) => {
  return (
    <div ref={ref} className={cn('seccl-pill', className)} {...HTMLProps} style={setStyle(theme, HTMLProps.style)}>
      {startAdornment && <span className="seccl-pill-start-adornment">{startAdornment}</span>}
      {content}
      {endAdornment && <span className="seccl-pill-end-adornment">{endAdornment}</span>}
    </div>
  );
};

/**
 * The Pill displays a stylized "pill" UI element.
 */
export const Pill = React.forwardRef(PillComponent);
