import * as React from 'react';
import { InputAdornment } from '@mui/material';

import { IconButton } from '../iconButton';
import { Icons } from '../icons';
import { Spinner } from '../spinner';

/**
 * Props for the Adornment component.
 */
interface IAdornmentProps {
  /**
   * Content to render.
   */
  content?: React.ReactNode;
  /**
   * The position of the adornment.
   */
  position: 'end' | 'start';
  /**
   * Boolean value to determine if the input is pending, showing a spinner.
   */
  pending?: boolean;
  /**
   * Boolean value to determine whether to render a X icon.
   */
  clearable?: boolean;
  /**
   * Function to call when the clear icon has been clicked.
   */
  onClear?: () => void;
}

// Note this is a function because the result of the function needs to be passed in for mui to calculate styling

/**
 * Returns the JSX element for the adornment based on the provided props.
 *
 * @param {IAdornmentProps} props - The props object containing the adornment configuration.
 * @returns {React.ReactNode | null} - The JSX element representing the adornment, or null if no content and no pending state are provided.
 */
export const getAdornment = ({ position, content, pending, clearable, onClear }: IAdornmentProps) => {
  if (!content && !pending) {
    return null;
  }

  return (
    <InputAdornment className="seccl-input-adornment" position={position} disableTypography>
      {clearable && (
        <IconButton ariaLabel="Clear" onClick={onClear}>
          <Icons.Close />
        </IconButton>
      )}
      {pending ? <Spinner className="seccl-input-adornment-spinner" /> : content}
    </InputAdornment>
  );
};
