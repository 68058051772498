import * as React from 'react';
import { AppBar, Drawer, Toolbar, useMediaQuery } from '@mui/material';

import { IconButton } from '../iconButton';
import { Icons } from '../icons';
import { Logo } from '../logo';
import { usePopover } from '../popover';
import { IconNavItem } from './desktopHeaderIconNavItem.component';
import { DesktopHeaderNavItem } from './desktopHeaderNavItem.component';
import { MobileNavItem } from './mobileNavItem.component';
import type { IIconNavigationItem, INavigationItem } from './types';

import './header.css';

/**
 * Props for Header component
 */
export interface IHeaderProps {
  /** The component to render when a href is present */
  linkComponent?: React.ElementType;
  /** Navigation items for header */
  navigationItems?: INavigationItem[];
  /** Search component for the header */
  searchComponent?: React.ReactNode;
  /** Settings menu navigation item */
  settingsMenu?: IIconNavigationItem;
  /** The breakpoint at which the header should switch to a dropdown. */
  mobileBreakpoint?: number;
}

/**
 * Header component for both desktop and mobile
 * @param {IHeaderProps} props - Props for the component
 * @returns {JSX.Element}
 */
export const Header: React.FC<IHeaderProps> = ({
  navigationItems = [],
  linkComponent,
  searchComponent,
  settingsMenu,
  mobileBreakpoint = 1150,
}) => {
  const popoverProps = usePopover({ openOnHover: true });
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(prevState => !prevState);
  };

  /**
   * Close the menu on window resize
   */
  React.useEffect(() => {
    const onResize = () => {
      if (!mobileOpen) setMobileOpen(false);
    };

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const container = typeof window !== 'undefined' ? () => window.document.body : undefined;

  const isDesktop = useMediaQuery(`(min-width: ${mobileBreakpoint}px)`);

  return (
    <>
      <AppBar className="seccl-header" position="sticky">
        <Toolbar className="seccl-header-toolbar">
          <Logo ariaLabel="Logo" />
          {isDesktop && (
            <>
              <nav className="seccl-navigation-container" aria-label="Navigation bar">
                {navigationItems
                  .filter(item => !item.hidden)
                  .map((navItem, i) => (
                    <DesktopHeaderNavItem
                      key={i}
                      {...navItem}
                      popoverProps={popoverProps}
                      linkComponent={linkComponent}
                    />
                  ))}
              </nav>
              <div className={'seccl-settings-container'}>
                {searchComponent}
                {settingsMenu && (
                  <IconNavItem
                    {...settingsMenu}
                    icon={settingsMenu?.icon ?? <Icons.Settings />}
                    popoverProps={popoverProps}
                    linkComponent={linkComponent}
                    ariaLabel="Settings menu"
                  />
                )}
              </div>
            </>
          )}
          {!!navigationItems?.length && !!settingsMenu && !isDesktop && (
            <IconButton
              className="seccl-navigation-icon-button"
              LinkComponent={linkComponent}
              onClick={handleDrawerToggle}
              ariaLabel={mobileOpen ? 'Close menu' : 'Open menu'}
            >
              {mobileOpen ? <Icons.Close /> : <Icons.HamburgerMenu />}
            </IconButton>
          )}
        </Toolbar>
      </AppBar>

      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        PaperProps={{
          className: 'seccl-draw-menu',
        }}
      >
        <nav className="seccl-mobile-nav" aria-label="Mobile navigation bar">
          <div className={'seccl-mobile-nav-search'}>{searchComponent}</div>
          {navigationItems
            .filter(item => !item.hidden)
            .map((navItem, i) => (
              <MobileNavItem key={i} {...navItem} linkComponent={linkComponent} />
            ))}
          {settingsMenu && (
            <MobileNavItem content="Settings" menuItems={settingsMenu.menuItems} linkComponent={linkComponent} />
          )}
        </nav>
      </Drawer>
    </>
  );
};
