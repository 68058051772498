import React from 'react';

import type { IErrorSectionProps } from './errorSection';
import { ErrorSection } from './errorSection';

interface IErrorPageProps {
  title: string;
  onErrorAction?: IErrorSectionProps['onErrorAction'];
}

/**
 * Represents an error page with a title and a button to navigate back to the dashboard.
 *
 * @component
 * @param {object} props - The props for the ErrorPage component.
 * @param {string} props.title - The title of the error page.
 * @returns {React.ReactElement} The rendered ErrorPage component.
 */
export const ErrorPage: React.FC<IErrorPageProps> = ({ title, onErrorAction = '/' }) => {
  return <ErrorSection title={title} onErrorAction={onErrorAction} />;
};
