import * as React from 'react';
import { Button } from '@mui/material';

import { Icons } from '../icons';
import type { usePopover } from '../popover';
import { PopoverMenu } from '../popover';
import type { INavigationItem } from './types';

/**
 * Props for DesktopHeaderNavItem component
 * @extends {INavigationItem}
 */
interface IDesktopHeaderNavItemProps extends INavigationItem {
  /** The component to render for links, driven off if the href on the navigation item is present */
  linkComponent?: React.ElementType;
  /** Props returned from the usePopover hook */
  popoverProps: ReturnType<typeof usePopover>;
}

/**
 * Navigation item component for the desktop header
 * @param {IDesktopHeaderNavItemProps} props - Props for the component
 * @returns The navigation item react component
 */
export const DesktopHeaderNavItem: React.FC<IDesktopHeaderNavItemProps> = ({
  content,
  href,
  linkComponent,
  menuItems,
  onClick,
  popoverProps: { activePopoverId, buttonProps, popoverProps },
}) => {
  const id = React.useId();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    buttonProps.onClick(e);
    if (onClick) onClick();
  };

  const endIcon = menuItems && menuItems.length > 0 && <Icons.ChevronDown size={15} />;

  return (
    <>
      <Button
        LinkComponent={linkComponent}
        href={href}
        disableFocusRipple
        disableRipple
        disableTouchRipple
        id={id}
        role={menuItems && menuItems.length > 0 ? 'button' : 'link'}
        {...(menuItems && menuItems.length > 0 ? buttonProps : {})}
        onClick={handleClick}
        color="inherit"
        className="seccl-navigation-button"
        endIcon={endIcon}
      >
        {content}
      </Button>
      {menuItems && menuItems.length > 0 && (
        <PopoverMenu
          {...popoverProps}
          open={activePopoverId === id && popoverProps.open}
          menuItems={menuItems}
          linkComponent={linkComponent}
        />
      )}
    </>
  );
};
