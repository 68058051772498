import React from 'react';
import cn from 'classnames';

import './listboxComponent.css';

export interface IListboxComponentProps extends React.HTMLAttributes<HTMLElement> {
  headers: string[];
}

export const ListboxComponent: React.ForwardRefRenderFunction<
  HTMLDivElement,
  React.PropsWithChildren<IListboxComponentProps>
> = ({ children, headers, ...props }, ref) => {
  return (
    <div {...props} role={props.role} ref={ref}>
      <ul className="listbox-container">
        <li className="listbox-header-row">
          {headers.map((header, index) => {
            return (
              <div className="listbox-cell" key={index}>
                {header}
              </div>
            );
          })}
        </li>
        {children}
      </ul>
    </div>
  );
};

export const BaseListBox = React.forwardRef(ListboxComponent);

export const ListboxOption: React.ForwardRefRenderFunction<
  HTMLLIElement,
  React.PropsWithChildren<React.HTMLAttributes<HTMLElement>>
> = ({ children, ...props }, ref) => {
  return (
    <li {...props} className={cn('listbox-row', props.className)} ref={ref}>
      {children}
    </li>
  );
};

export const BaseListBoxOption = React.forwardRef(ListboxOption);

export const ListboxCell: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <div className="listbox-cell">{children}</div>;
};
